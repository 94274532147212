<template>
  <div>
    <div class="flex flex-col text-14 mb-5 mt-2 font-bold">
      <h2 class="text-2xl font-bold">User Flexi Saving History</h2>
    </div>

    <div class="border border-solid border-blue-200">
      <datatable
        :data="flexiSavings.data"
        :columns="flexiSavings.columns"
        :filters="flexiSavings.filters"
        :query="flexiSavings.query"
        :loading="flexiSavings.loading"
        :ajax="true"
        :ajax-pagination="true"
        :url="`${this.$baseurl}/admin/savings/flexi/saving-history/${this.savingId()}/user/${this.userId()}`"
        :search-field="searchField"
        :on-click="click"
        ref="table"
      >
        <template #header>
          <!-- <search-form
            v-model="flexiSavings.query"
            class="mb-5"
            placeholder="Search for user's name, ID, flexi's id, phone number"
            @submit="reloadData"
          /> -->

          <div class="flex items-center mb-5">
          <template v-for="(filter, filterIndex) in flexiSavings.filters">
            <div
              :key="filterIndex"
              class="processor-filter-item"
              :class="{ active: currentFilter === filter }"
              @click.prevent="setFilter(filter)"
            >
              {{ filter.title }}
            </div>
          </template>
        </div>
        </template>
      </datatable>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    searchQuery: {
      type: String,
      default: ''
    },
    searchField: {
      type: [HTMLInputElement],
      default: null
    }
  },
  data() {
    return {
      checkmark: require('@/assets/checkmark-base.svg'),
      currentFilter: null,
      flexiSavings: this.$options.resource([], {
        query: '',
        filters: [
          { name: 'all', title: 'All' },
          { name: 'deposit', title: 'Deposits' },
          { name: 'withdrawal', title: 'Withdrawals' },
        ],
        selected: null,
        columns: [
         
          {
            name: 'category',
            th: 'Category'
          },
          
          {
            name: 'currency',
            th: 'Currency'
          },

          {
            name: 'reference',
            th: 'Reference'
          },
          {
            name: 'amount',
            th: 'Amount',
            render: saving => `₦ ${this.$options.filters.currency(saving.amount)}`

          },
        
          {
            name: 'status',
            th: 'Status',
            render: (saving, status) => this.flexiStatusCheck(status),
          },

          {
            name: 'created_at',
            th: 'Created At',
            render: (transaction, created_at) => {
						return (
							this.$options.filters.dateFormat(created_at, "M dd") +
							" at " +
							this.$options.filters.dateFormat(created_at, "h:ia")
						);
					},          },
       
        ],
      }),
      form: this.$options.basicForm(['status']),
    };
  },
  mounted() {
    this.$options.listenForClick.apply(this);
  },
 
  methods: {
    // click(defaultSaving) {
        // console.log(defaultSaving)
    //  this.$router.push({
    //     name: "saving-history",
    //     params: { userId: defaultSaving?.user_id }
    //   });
    // },
    reloadData() {
      this.$refs.table.loadAjaxData();
    },
    savingId() {
      return this.$route.params.savingId;
    },
    userId() {
      return this.$route.params.userId;
    },
    setFilter(filter) {
      if (this.currentFilter === filter) {
        this.currentFilter = null
      } else {
        this.currentFilter = filter
      }

      this.$refs.table.clickedFilter(this.currentFilter)
    },
   
    flexiStatusCheck(status) {
      switch (status) {
        case "closed":
          return `<div class="rounded-sm border border-blue-500 text-blue-500 bg-blue-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case "liquidated":
        return `<div class="rounded-sm border border-orange-500 text-orange-500 bg-orange-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
        case "running":
        default:
          return `<div class="rounded-sm border border-green-500 text-green-500 bg-green-100 text-xs px-2 py-1 inline-block">
                    ${status}
                  </div>`;
      }
    },
  }
};
</script>
